import React from 'react'
import type { ReactNode } from 'react'
import { ThemeProvider as StyledThemeProvider, DefaultTheme } from 'styled-components'
import { extendTheme } from '@mui/joy/styles'
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  CSSObject,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles'
import { StyledEngineProvider, CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles'
type Props = {
  children: ReactNode
}

// our main theme object
const theme = {
  components: {
    MUIDataTable: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        } as CSSObject,
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          maxWidth: 600,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        } as CSSObject,
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          maxHeight: 48,
          paddingTop: 4,
          paddingBottom: 4,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        } as CSSObject,
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          minHeight: 48,
          display: 'flex',
          overflow: 'hidden',
        } as CSSObject,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#fafafa',
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
  palette: {
    primary: {
      light: '#5099ca',
      main: '#046b99',
      dark: '#00406b',
      contrastText: '#fff',
    },
    secondary: {
      light: '#67eeff',
      main: '#00bbf0',
      dark: '#008bbd',
      contrastText: '#000',
    },
    events: {
      comment: '#bc5100',
    },
    white: '#fff',
    hover: {
      selected: '#e0e0e0',
      notSelected: '#fafafa',
    },
  },
}

const MuiJoyTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          500: '#046b99',
          solidHoverBg: '#00406b',
        },
        background: {
          body: '#fafafa',
        },
      },
    },
  },
  components: {},
})

const muiTheme = createTheme(theme)

// use <ThemeProvider> to pass theme down the tree
const ThemeProvider = ({ children }: Props) => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={{ [MATERIAL_THEME_ID]: muiTheme }}>
      <JoyCssVarsProvider theme={MuiJoyTheme}>
        <StyledThemeProvider theme={muiTheme as unknown as DefaultTheme}>
          {children}
        </StyledThemeProvider>
      </JoyCssVarsProvider>
    </MuiThemeProvider>
  </StyledEngineProvider>
)

export default ThemeProvider
