const cookieKeys = {
  AUTH_TOKEN: 'AUTH_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  ZENDESK_AUTH_TOKEN: 'ZENDESK_AUTH_TOKEN',
}

const colors = {
  NODAFI_BLUE: '#00406b',
  MONEY_GREEN: '#118c4f',
  DISABLED_GRAY: '#d3d3d3',
}

const SUPPORT_EMAIL = 'hello@nodafi.com'
const APP_STORE_URL = 'https://apps.apple.com/us/app/nodafi/id1399122213'
const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.nodafi.app'

// constants
const PROTOCOL = process.env.REACT_APP_SERVER_URL?.includes('localhost') ? '' : 's' // 's' || ''
const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? 'api.nodafi.com/graphql' // 'localhost:4000' or api.nodafi-staging.com
const BASE_SERVER_URL = SERVER_URL.replace('/graphql', '')
const GRAPHQL_URI = `http${PROTOCOL}://${SERVER_URL}`

// TODO: Expose these in GraphQL API
export enum StandardResourceIdentifiers {
  TICKET = 'TICKET',
  USER = 'USER',
  GROUP = 'GROUP',
  SPACE = 'SPACE',
  LOCATION = 'LOCATION',
  SURVEY = 'SURVEY',
  PART = 'PART',
  ASSET = 'ASSET',
  COST_ENTRY = 'COST_ENTRY',
  EXTERNAL_ORGANIZATION = 'EXTERNAL_ORGANIZATION',
  PROCEDURE = 'PROCEDURE',
}

const Filters = {
  NONE: 'None',
  OR: 'OR',
}

const BooleanStringValues = {
  YES: 'Yes',
  NO: 'No',
}

const ApiWaitTimeForDebouncedSearch = 500
const SpacePinInitialVal = 1001

const FilterConstants = {
  Tickets: {
    ID: 'id',
    TITLE: 'title',
    CREATEDAT: 'createdAt',
    STATUS: 'status',
    PRIORITY: 'priority',
    ASSIGNEE: 'assignee',
    ASSIGNEDGROUP: 'assignedGroup',
    COMPLETEDAT: 'completedAt',
    COSTENTRIESDATA: 'costEntriesData',
    COSTENTRIESTIMELOGGEDHOURS: 'costEntriesTimeLoggedHours',
    COSTENTRIESTOTALCOST: 'costEntriesTotalCost',
    LOCATION: 'location',
    SPACE: 'space',
  },
}
type FilterConstantsBoolean = {
  [K in keyof typeof FilterConstants]: {
    [P in keyof (typeof FilterConstants)[K]]?: boolean
  }
}

type SkipTicketsTableFields = FilterConstantsBoolean['Tickets']

// values are in milliseconds.
const ToastAutoCloseTime = {
  fiveSeconds: 5000,
  tenSeconds: 10000,
  fifteenSeconds: 15000,
}

const fileUploadMaxSize = 15 // in MB

// file upload error messages.
const fileUploadErrorMessages = {
  MAX_FILES_LIMIT_EXCEEDED: 'Can not upload more than 5 files',
  MAX_FILE_SIZE_LIMIT_EXCEEDED: `Max file size allowed is ${fileUploadMaxSize} mb`,
}

const TicketStatusCategories = {
  NOT_STARTED: 'NOT_STARTED',
  ACTIVE: 'ACTIVE',
  DONE: 'DONE',
}

const FIELD_REQUIRED_MESSAGE = 'This is required.'

type ResponseError = {
  title: string
  messages: string[]
}

const ticketFieldKeys = {
  id: 'id',
  flags: 'flags',
  sequentialId: 'sequentialId',
  title: 'title',
  description: 'description',
  message: 'message',
  image: 'image',
  location: 'location',
  assets: 'assets',
  space: 'space',
  issue: 'issue',
  files: 'files',
  assignedGroup: 'assignedGroup',
  assignee: 'assignee',
  dueDate: 'dueDate',
  startDate: 'startDate',
  calendarEvent: 'calendarEvent',
  vendors: 'vendors',
  customers: 'customers',
  watchers: 'watchers',
  priority: 'priority',
  status: 'status',
  costEntries: 'costEntries',
  part: 'part',
  isSubscribedToTicketUpdates: 'isSubscribedToTicketUpdates',
  requester: 'requester',
  fieldEntries: 'fieldEntries',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  completedAt: 'completedAt',
  procedure: 'procedure',
} as const

const nestedTicketFieldKeys = {
  requesterComment: 'requesterComment',
  locationName: 'locationName',
  spaceName: 'spaceName',
  spaceDetail: 'spaceDetail',
  assetNames: 'assetNames',
  vendorName: 'vendorName',
  customerName: 'customerName',
  issueName: 'issueName',
  costEntriesTotalCost: 'costEntriesTotalCost',
  costEntriesTimeLoggedHours: 'costEntriesTimeLoggedHours',
  completedOn: 'completedOn',
  latestComment: 'latestComment',
} as const

const ANONYMOUS_USER = 'Anonymous'

const DATE_CONSTANTS = {
  MIN_DATE: new Date(0),
  MAX_DATE: new Date('9999-12-31 00:00:00.000'),
}

enum LOOKUP_OPERATION {
  SEARCH = 'SEARCH',
  GET_MORE = 'GET_MORE',
}

const REACT_INFINITE_SCROLL_HOOK = {
  ID: 'react-infinite-scroll-hook',
}

const STYLE_WIDTHS = {
  FULL_WIDTH: '100%',
  HALF_WIDTH: '50%',
  THREE_QUARTER_WIDTH: '75%',
  QUARTER_WIDTH: '25%',
}

const CustomDataFields = {
  PICKER_OPTIONS: 'pickerOptions',
}
const TEST_ORGANIZATIONS = ['dev', 'demo']

const CUSTOM_REQUEST_HEADERS = {
  SUBDOMAIN_HEADER_KEY: 'x-nodafi-account-subdomain',
  ACCOUNT_ID_HEADER_KEY: 'x-nodafi-account-id',
}

const TIMEZONE_MAP = {
  'Africa/Algiers': 'West Central Africa',
  'Africa/Cairo': 'Cairo',
  'Africa/Casablanca': 'Casablanca, Monrovia',
  'Africa/Harare': 'Harare, Pretoria',
  'Africa/Nairobi': 'Nairobi',
  'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
  'America/Belize': 'Central America',
  'America/Bogota': 'Bogota, Lima, Quito',
  'America/Boise': 'Mountain Time',
  'America/Caracas': 'Caracas, La Paz',
  'America/Chicago': 'Central Time',
  'America/Chihuahua': 'Chihuahua, La Paz, Mazatlan',
  'America/Dawson': 'Dawson, Yukon',
  'America/Detroit': 'Eastern Time',
  'America/Godthab': 'Greenland',
  'America/Juneau': 'Alaska',
  'America/Los_Angeles': 'Pacific Time',
  'America/Mexico_City': 'Guadalajara, Mexico City, Monterrey',
  'America/Montevideo': 'Montevideo',
  'America/Phoenix': 'Arizona',
  'America/Regina': 'Saskatchewan',
  'America/Santiago': 'Santiago',
  'America/Sao_Paulo': 'Brasilia',
  'America/St_Johns': 'Newfoundland and Labrador',
  'America/Tijuana': 'Tijuana',
  'Asia/Almaty': 'Almaty, Novosibirsk',
  'Asia/Baghdad': 'Baghdad',
  'Asia/Baku': 'Baku, Tbilisi, Yerevan',
  'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
  'Asia/Colombo': 'Sri Jayawardenepura',
  'Asia/Dhaka': 'Astana, Dhaka',
  'Asia/Dubai': 'Abu Dhabi, Muscat',
  'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
  'Asia/Jerusalem': 'Jerusalem',
  'Asia/Kabul': 'Kabul',
  'Asia/Kamchatka': 'Kamchatka, Marshall Islands',
  'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
  'Asia/Kathmandu': 'Kathmandu',
  'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, New Delhi',
  'Asia/Krasnoyarsk': 'Krasnoyarsk',
  'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
  'Asia/Kuwait': 'Kuwait, Riyadh',
  'Asia/Magadan': 'Magadan, Solomon Islands, New Caledonia',
  'Asia/Rangoon': 'Yangon Rangoon',
  'Asia/Seoul': 'Seoul',
  'Asia/Shanghai': 'Beijing, Chongqing, Hong Kong SAR, Urumqi',
  'Asia/Taipei': 'Taipei',
  'Asia/Tehran': 'Tehran',
  'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
  'Asia/Vladivostok': 'Vladivostok',
  'Asia/Yakutsk': 'Yakutsk',
  'Asia/Yekaterinburg': 'Ekaterinburg',
  'Atlantic/Azores': 'Azores',
  'Atlantic/Canary': 'Canary Islands',
  'Atlantic/Cape_Verde': 'Cape Verde Islands',
  'Australia/Adelaide': 'Adelaide',
  'Australia/Brisbane': 'Brisbane',
  'Australia/Darwin': 'Darwin',
  'Australia/Hobart': 'Hobart',
  'Australia/Perth': 'Perth',
  'Australia/Sydney': 'Canberra, Melbourne, Sydney',
  'Europe/Amsterdam': 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  'Europe/Athens': 'Athens',
  'Europe/Belgrade': 'Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  'Europe/Brussels': 'Brussels, Copenhagen, Madrid, Paris',
  'Europe/Bucharest': 'Bucharest',
  'Europe/Dublin': 'Dublin',
  'Europe/Helsinki': 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  'Europe/Lisbon': 'Lisbon',
  'Europe/London': 'Edinburgh, London',
  'Europe/Moscow': 'Istanbul, Minsk, Moscow, St. Petersburg, Volgograd',
  'Europe/Sarajevo': 'Sarajevo, Skopje, Warsaw, Zagreb',
  GMT: 'UTC',
  'Pacific/Auckland': 'Auckland, Wellington',
  'Pacific/Fiji': 'Fiji Islands',
  'Pacific/Guam': 'Guam, Port Moresby',
  'Pacific/Honolulu': 'Hawaii',
  'Pacific/Midway': 'Midway Island, Samoa',
  'Pacific/Tongatapu': "Nuku'alofa",
}

export {
  PROTOCOL,
  SERVER_URL,
  BASE_SERVER_URL,
  GRAPHQL_URI,
  cookieKeys,
  colors,
  SUPPORT_EMAIL,
  APP_STORE_URL,
  GOOGLE_PLAY_URL,
  FIELD_REQUIRED_MESSAGE,
  DATE_CONSTANTS,
  Filters,
  ApiWaitTimeForDebouncedSearch,
  FilterConstants,
  ToastAutoCloseTime,
  SpacePinInitialVal,
  fileUploadErrorMessages,
  TicketStatusCategories,
  BooleanStringValues,
  ticketFieldKeys,
  nestedTicketFieldKeys,
  ANONYMOUS_USER,
  REACT_INFINITE_SCROLL_HOOK,
  LOOKUP_OPERATION,
  STYLE_WIDTHS,
  fileUploadMaxSize,
  CustomDataFields,
  TEST_ORGANIZATIONS,
  CUSTOM_REQUEST_HEADERS,
  TIMEZONE_MAP,
}
export type { ResponseError, FilterConstantsBoolean, SkipTicketsTableFields }
